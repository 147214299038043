<template>
<div>
  <img class="home_img" src="../assets/home/1.png"/>
  <img class="home_img" src="../assets/home/2.png"/>
  <img class="home_img" src="../assets/home/3.png"/>
  <img class="home_img" src="../assets/home/4.png"/>
  <div>
    <a style="display: flex;justify-content: center; align-items: center" target="_black" href="http://beian.miit.gov.cn">
      <img class="gov_img" src="../assets/gov.png"/>
      <span style="margin-left: 5px;color: blue">浙ICP备2023024248号-{{num}}</span>
    </a>
    <div style="margin-top: 10px; background-color: black;color: white;text-align: center;font-size: 26px">
      <div>杭州知视云科技有限公司</div>
      <div>联系我们:0571-86920303 邮箱:barry@flyoner.com</div>
      <div>地址:浙江省杭州市上城区九盛路9号25幢B座3楼301室</div>
    </div>
  </div>


</div>
</template>

<script>
export default {
  name: "Home",
  mounted(){
    let host = window.location.host;
    if (host.indexOf("chaojifaxing") > -1) {
      this.num = 4
    }
  },
  data(){
    return{
      num : 1
    }
  }
}
</script>

<style scoped>
  .home_img{
    width: 100%;
  }
  .gov_img{
    width: 20px;
    height: 20px;
  }
</style>
